<template>
  <v-layout column fill-height class="text-only-mission">
    <!-- Mission question -->
    <v-flex v-if="isScribe && !isHost">
      <PictureCapture class="fill-height" @sendFile="onSendFile" />
    </v-flex>
    <template v-else>
      <v-flex d-flex class="mission-instructions">
        <ResizableText :message="instructions" />
      </v-flex>
      <v-flex>
        <button class="start-video-button not-scribe">
          Only Scribes Take Photo
        </button>
      </v-flex>
    </template>
  </v-layout>
</template>

<script>
import ResizableText from "./ResizableText.vue"
import uniqid from "uniqid"
import { GameMixin } from "@/mixins"
import { uploadMedia } from "@/services/storage.service"
const PictureCapture = () => import("@/components/PictureCapture")

export default {
  name: "TextOnly",
  mixins: [GameMixin],
  components: {
    ResizableText,
    PictureCapture
  },
  computed: {
    instructions() {
      return this.currentMission.instructions
    },
    isScribe() {
      return this.$store.getters.isScribe
    },
    isHost() {
      return (
        this.$store.getters.user.role == "facilitator" ||
        this.$store.getters.user.role == "audit"
      )
    },
    user() {
      return this.$store.getters.user
    },
    token() {
      return this.$store.state.auth.token
    },
    currentMission() {
      return this.$store.getters.getCurrentMission
    },
    currentMissionID() {
      return this.$store.getters.currentMission
    }
  },
  methods: {
    async onSendFile(file) {
      const fileName = `gamephotos/${uniqid()}-${this.user.username}.jpg`
      try {
        this.imageUrl = await uploadMedia({
          fileName,
          blob: file,
          token: this.token
        })
      } catch (e) {
        alert(
          "It looks like you are behind a firewall or VPN and therefore can't save your photo."
        )
        this.imageUrl = "https://jaznau.com/wp-content/uploads/2019/08/vpn.jpg"
      }

      this.hasImage = true
      this.missionStatus = "completed"

      this.checkAnswer(this.currentMissionID)
    }
  }
}
</script>

<style lang="scss">
.text-only-mission {
  .mission-instructions {
    padding: 25px 10px 5px;
  }
}

.start-video-button:hover {
  background-color: $color-brown;
  color: $color-white;
  outline: none;
}

.start-video-button {
  background-color: $primary_accent_color !important;
  color: $color-beige-light;
  border-radius: 6px;
  padding: 8px 18px;
  font-size: 22px;
  font-weight: bolder;
  outline: none;
}

.not-scribe {
  background-color: $color-grey-light3 !important;
  cursor: none;
}
</style>
