var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticClass: "text-only-mission",
      attrs: { column: "", "fill-height": "" }
    },
    [
      _vm.isScribe && !_vm.isHost
        ? _c(
            "v-flex",
            [
              _c("PictureCapture", {
                staticClass: "fill-height",
                on: { sendFile: _vm.onSendFile }
              })
            ],
            1
          )
        : [
            _c(
              "v-flex",
              { staticClass: "mission-instructions", attrs: { "d-flex": "" } },
              [_c("ResizableText", { attrs: { message: _vm.instructions } })],
              1
            ),
            _c("v-flex", [
              _c("button", { staticClass: "start-video-button not-scribe" }, [
                _vm._v(" Only Scribes Take Photo ")
              ])
            ])
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }